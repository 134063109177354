import React from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import swal from 'sweetalert';
import './calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = BigCalendar.momentLocalizer(moment);

const Event = props => {
  if (props.event.title) {
    return <div>{props.event.title}</div>;
  }
  return null;
};

const Calendar = ({ trainings, goToTrain, currentUser, createTrain }) =>
  trainings ? (
    <BigCalendar
      localizer={localizer}
      events={trainings}
      startAccessor="start"
      endAccessor="end"
      onSelectSlot={({ start }) => {
        swal({
          text: 'Введіть назву',
          content: 'input',
          button: {
            text: 'ок',
            closeModal: false
          }
        }).then(name => {
          swal({
            text: 'Введіть посилання на фото',
            content: 'input',
            button: {
              text: 'ок'
            }
          }).then(image => {
            swal({
              text: 'Введіть ціну',
              content: 'input',
              button: {
                text: 'ок'
              }
            }).then((price = 150) => {
              swal({
                text:
                  'Введіть місце (atm, truhanov, truhanov2, track, chabani, vishgorod, zhitomir, dimer, ds)',
                content: 'input',
                button: {
                  text: 'ок'
                }
              }).then((place = 'track') => {
                swal({
                  text: 'Посилання на маршрут у Strava:',
                  content: 'input',
                  button: {
                    text: 'ок'
                  }
                }).then((route = '') => {
                  createTrain({
                    Date: start,
                    name,
                    participants: [],
                    image,
                    active: true,
                    price: parseInt(price),
                    place,
                    route,
                    level: 'm'
                  });
                });
              });
            });
          });
        });
      }}
      selectable
      defaultView={'week'}
      components={{ event: Event }}
    />
  ) : null;

export default Calendar;
