import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';

import Layout from '../components/layout';
import Calendar from '../components/Calendar/Calendar';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.props.firebase.base.listenToCollection('trainings', {
      context: this,
      state: 'bar',
      then() {
        this.props.trainings.getTrainings().then(trainings => this.setState({ trainings }));
      }
    });
  }

  state = {
    trainings: []
  };

  componentDidMount() {
    if (this.props.firebase) {
      this.props.trainings.getTrainings().then(trainings => this.setState({ trainings }));
    }
  }

  render() {
    const currentUser = this.props.firebase?.auth?.currentUser?.uid;
    return (
      <div style={{ backgroundColor: 'white' }}>
        <Calendar
          trainings={this.state.trainings}
          goToTrain={this.props.trainings.goToTrain}
          createTrain={this.props.trainings.createTrain}
          currentUser={currentUser}
        />
      </div>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(LandingPage));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
